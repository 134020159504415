import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import gatsbyLogo from '../images/skill-icons/gatsby-icon.svg'
import reactLogo from '../images/skill-icons/react-icon.svg'
import nodejsLogo from '../images/skill-icons/nodejs-icon.svg'
import expressLogo from '../images/skill-icons/express-icon.svg'
import graphqlLogo from '../images/skill-icons/graphql-icon.svg'
import apolloLogo from '../images/skill-icons/apollo-icon.svg'
import reduxLogo from '../images/skill-icons/redux-icon.svg'

export default ({ data }) => (
  <Layout>
    <h1 style={{ textAlign: 'center' }}>{ data.site.siteMetadata.title }</h1>

    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ flex: '0 1 100px', padding: '.5rem' }}>

        <img src={reactLogo} alt="react logo" />

      </div>
      <div style={{ flex: '0 1 100px', padding: '.5rem' }}>

        <img src={reduxLogo} alt="redux logo" />

      </div>
      <div style={{ flex: '0 1 100px', padding: '.5rem' }}>

        <img src={nodejsLogo} alt="node logo" />

      </div>
      <div style={{ flex: '0 1 160px', padding: '.5rem' }}>

        <img src={expressLogo} alt="express logo" />

      </div>
      <div style={{ flex: '0 1 100px', padding: '.5rem' }}>

        <img src={graphqlLogo} alt="graphql logo" />

      </div>
      <div style={{ flex: '0 1 100px', padding: '.5rem' }}>

        <img src={apolloLogo} alt="apollo logo" />

      </div>
      <div style={{ flex: '0 1 100px', padding: '.5rem' }}>

        <img src={gatsbyLogo} alt="gatsby logo" />

      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
